.bulletClass {
  border-color: #c6d344;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 0;
  border-width: 2px;
  display: block;
  background-color: transparent;
  cursor: pointer;
}

.bulletActiveClass {
  background-color: #c6d344;
}

.swiperPagination {
  border: 1px solid red;
}

.swiper-pagination-bullets {
  display: flex;
  width: 100%;
  left: 0;
  bottom: 0;

  justify-content: center;
  gap: 10px;
}
